/** @jsx jsx */
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import { Fragment, useEffect } from "react"

import { trackingClient } from "@trueskin-web/apis"
import {
  Container,
  ExpansionPanel,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Slugs, useTranslation } from "@trueskin-web/translations"

import BlogHero from "../block-library/blog-hero"
import BlogSlider from "../block-library/blog-slider"
import AuthorMarkup from "../block-library/components/author-markup"
import Blocks from "../block-library/components/blocks"
import Breadcrumbs from "../block-library/components/breadcrumbs"
import FaqMarkup from "../block-library/components/faq-markup"
import MoreInformationLinks from "../block-library/components/more-information-links"
import SEODetails from "../block-library/components/seo-details"
import FaqContainer from "../components/faq-container"
import Layout from "../components/layout"

import BlogTableOfContent from "./blog-content"

const transformToItems = (
  data,
  blogCategory,
  blogSubcategoryPostsCount,
  backgroundColor,
  extraProps
) =>
  data.map((e) => ({
    id: e.id,
    heading: e.name,
    description: e.heading,
    image: e.image,
    background:
      backgroundColor || blogCategory.backgroundColor || "none transparent",
    articleCount: blogSubcategoryPostsCount.find(
      (x) => x.subcategoryId === e.id
    ).count,
    href: `/${blogCategory.slug}/${e.slug}`,
    ...extraProps,
  }))

const BlogCategoryBreadcrumbs = ({ blogCategory }) => {
  const { t } = useTranslation()

  return (
    <Breadcrumbs
      items={[
        {
          title: t("Templates.BlogCategory.title"),
          href: Slugs["skin-guide"].url,
        },
        { title: blogCategory.name },
      ]}
    />
  )
}

const BlogCategory = ({
  data: {
    strapi: {
      blogCategory,
      blogCategorySubcategories,
      blogSubcategoryPostsCount,
      blogCategoryToc,
    },
  },
}) => {
  const location = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    trackingClient.trackCampaignIfPresent(location)
  }, [location])

  return (
    <Fragment>
      <AuthorMarkup
        url={blogCategory.slug}
        title={blogCategory.heading}
        description={
          blogCategory.seoDetails && blogCategory.seoDetails.metaDescription
        }
        datePublished={blogCategory.createdAt}
        dateModified={blogCategory.updatedAt}
      />

      {blogCategory.showFaq && (
        <FaqMarkup faqSection={blogCategory.faqSection} />
      )}

      <Layout title={blogCategory.name}>
        <SEODetails title={blogCategory.name} {...blogCategory.seoDetails} />

        {blogCategory.headerBlock?.length ? (
          <div sx={{ mt: [5, 7], mb: 5 }}>
            <Container>
              <Blocks blocks={blogCategory.headerBlock} />
              <BlogCategoryBreadcrumbs blogCategory={blogCategory} />
            </Container>
          </div>
        ) : (
          <BlogHero
            categoryLayout
            {...blogCategory.hero}
            sx={{ mb: 5 }}
            breadcrumbs={
              <BlogCategoryBreadcrumbs blogCategory={blogCategory} />
            }
          />
        )}

        <div sx={{ mt: [null, null], mb: 9 }}>
          <Container>
            <BlogSlider
              // heading={`${blogSubcategoryPostsCount.map((spc) => spc.count).reduce((prev, crt) => prev + crt, 0)} Unterkategorien`}
              heading={t("Templates.BlogCategory.subcategories", {
                count: blogCategorySubcategories.length,
              })}
              headingProps={{
                as: "h3",
                sx: {
                  width: ["calc(100% + 32px)", "100%"],
                  mx: [-4, 0],
                  textAlign: "center",
                  backgroundColor: blogCategory.backgroundColor,
                  py: "18px",
                  mb: 7,
                },
              }}
              items={transformToItems(
                blogCategorySubcategories,
                blogCategory,
                blogSubcategoryPostsCount,
                null,
                {
                  fullLink: true,
                }
              )}
            />
            <BlogTableOfContent
              toc={blogCategoryToc}
              blocks={blogCategory.blocks}
              author={blogCategory.pageAuthor}
              hideTableOfContents={blogCategory.hideTableOfContents}
            />
          </Container>
        </div>

        {blogCategory.showFaq &&
          blogCategory.faqSection &&
          blogCategory.faqSection.length && (
            <div sx={{ mt: [null, null], mb: 9 }}>
              <Container>
                <FaqContainer>
                  {blogCategory.faqSection.map(
                    ({ question, answer }, index) => (
                      <ExpansionPanel key={index} title={question}>
                        <UserGeneratedHtml html={answer} />
                      </ExpansionPanel>
                    )
                  )}
                </FaqContainer>
              </Container>
            </div>
          )}

        {/* <div sx={{ bg: "lightGray", variant: "layout.sectionPadding" }}>
          <Container
            sx={{
              display: "grid",
              gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
              gap: [4, 7],
            }}
          >
            <Heading as="h5" sx={{ fontSize: [5, 6] }}>
              {blogCategory.seoTitle}
            </Heading>
            <UserGeneratedHtml html={blogCategory.seoContent} />
          </Container>
        </div> */}

        <MoreInformationLinks linksHtml={blogCategory.preFooterLinks} />
      </Layout>
    </Fragment>
  )
}

export const query = graphql`
  query ($blogCategoryId: ID!) {
    strapi {
      blogCategoryToc(where: { id: $blogCategoryId })
      blogCategory(id: $blogCategoryId) {
        name
        slug
        heading
        description
        backgroundColor
        hideTableOfContents
        showFaq
        faqSection {
          question
          answer
        }
        seoTitle
        seoContent
        seoDetails {
          ...SEODetailsFragment
        }
        preFooterLinks
        updatedAt
        createdAt
        pageAuthor {
          title
          description
          doctor {
            name
            specialty
            slug
            image {
              url
              imageFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
          }
        }
        hero {
          ...BlogHeroFragment
        }
        headerBlock {
          __typename
          ... on Strapi_ComponentBlocksHero {
            ...HeroFragment
          }
          ... on Strapi_ComponentBlocksFullSizeHero {
            ...FullSizeHeroFragment
          }
          ... on Strapi_ComponentBlocksLpHero {
            ...LpHeroFragment
          }
          ... on Strapi_ComponentBlocksHeroTrustpilot {
            ...HeroTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksHeroPriceTrustpilot {
            ...HeroPriceTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksSmallHero {
            ...SmallHeroFragment
          }
          ... on Strapi_ComponentBlocksTextHero {
            ...TextHeroFragment
          }
        }
        blocks {
          __typename
          ... on Strapi_ComponentAtomsRichContent {
            ...RichContentFragment
          }
          ... on Strapi_ComponentAtomsImage {
            ...ImageFragment
          }
          ... on Strapi_ComponentAtomsSpace {
            ...SpaceFragment
          }
          ... on Strapi_ComponentBlogVideo {
            ...VideoFragment
          }
          ... on Strapi_ComponentLandingContentCtaList {
            ...ContentCtaListFragment
          }
          ... on Strapi_ComponentBlocksContentCta {
            ...ContentCtaFragment
          }
          ... on Strapi_ComponentBlocksBlogPitch {
            ...BlogPitchFragment
          }
          ... on Strapi_ComponentBlocksHowItWorks {
            ...HowItWorksFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksVisually {
            ...HowItWorksVisuallyFragment
          }
          ... on Strapi_ComponentBlocksHero {
            ...HeroFragment
          }
          ... on Strapi_ComponentBlocksFullSizeHero {
            ...FullSizeHeroFragment
          }
          ... on Strapi_ComponentBlocksLpHero {
            ...LpHeroFragment
          }
          ... on Strapi_ComponentBlocksPrStrip {
            ...PrStripFragment
          }
          ... on Strapi_ComponentBlocksConditionsList {
            ...ConditionsListFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksGuide {
            ...HowItWorksGuideFragment
          }
          ... on Strapi_ComponentBlocksMedicalIntro {
            ...MedicalIntroFragment
          }
          ... on Strapi_ComponentBlocksSkinGuideIndex {
            ...SkinGuideIndexFragment
          }
          ... on Strapi_ComponentBlocksSetIntro {
            ...SetIntroFragment
          }
          ... on Strapi_ComponentBlocksHeroTrustpilot {
            ...HeroTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksHeroPriceTrustpilot {
            ...HeroPriceTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksProgressBoard {
            ...ProgressBoardFragment
          }
          ... on Strapi_ComponentBlocksSmallHero {
            ...SmallHeroFragment
          }
          ... on Strapi_ComponentBlocksTextHero {
            ...TextHeroFragment
          }
          ... on Strapi_ComponentLandingList {
            ...ListFragment
          }
          ... on Strapi_ComponentLandingContentCtaBadges {
            ...ContentCtaBadgesFragment
          }
          ... on Strapi_ComponentLandingBadges {
            ...BadgesFragment
          }
          ... on Strapi_ComponentBlocksProcess {
            ...ProcessFragment
          }
          ... on Strapi_ComponentBlocksHighlights {
            ...HighlightsFragment
          }
          ... on Strapi_ComponentBlocksContentVideo {
            ...ContentVideoFragment
          }
          ... on Strapi_ComponentBlocksReviews {
            ...ReviewsFragment
          }
          ... on Strapi_ComponentBlocksDoctorsIntro {
            ...DoctorsIntroFragment
          }
          ... on Strapi_ComponentBlocksProductsHighlight {
            ...ProductsHighlightFragment
          }
          ... on Strapi_ComponentBlocksBeforeAfter {
            ...BeforeAfterFragment
          }
          ... on Strapi_ComponentBlocksMirroredHighlights {
            ...MirroredHighlightsFragment
          }
          ... on Strapi_ComponentBlocksPrefooter {
            ...PrefooterFragment
          }
          ... on Strapi_ComponentBlocksDoctors {
            ...DoctorsFragment
          }
          ... on Strapi_ComponentBlocksShowcaseProducts {
            ...ShowcaseProductsFragment
          }
          ... on Strapi_ComponentBlocksTreatmentGoals {
            ...TreatmentGoalsFragment
          }
          ... on Strapi_ComponentBlocksDoctorsBoard {
            ...DoctorsBoardFragment
          }
          ... on Strapi_ComponentBlocksFaqs {
            ...FaqsFragment
          }
          ... on Strapi_ComponentBlocksInstagramWidget {
            ...InstagramWidgetFragment
          }
          ... on Strapi_ComponentBlocksSocialReviews {
            ...SocialReviewsFragment
          }
          ... on Strapi_ComponentBlocksMedicHero {
            ...HeroMedicFragment
          }
          ... on Strapi_ComponentBlocksDoctorTeam {
            ...DoctorTeamFragment
          }
          ... on Strapi_ComponentBlocksDoctorMembers {
            ...DoctorMembersFragment
          }
          ... on Strapi_ComponentBlocksTitle {
            ...TitleFragment
          }
          ... on Strapi_ComponentBlocksThreePartContent {
            ...ThreePartContentFragment
          }
          ... on Strapi_ComponentBlocksZigZag {
            ...ZigZagFragment
          }
          ... on Strapi_ComponentBlocksNewsletterSignup {
            ...NewsletterSignupFragment
          }
        }
      }
      blogCategorySubcategories(where: { id: $blogCategoryId }) {
        id
        name
        slug
        heading
        image {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 500, aspectRatio: 1, layout: CONSTRAINED)
            }
          }
        }
      }
      blogSubcategoryPostsCount(where: { id: $blogCategoryId }) {
        subcategoryId
        count
      }
    }
  }
`

export default BlogCategory
