/** @jsx jsx */
import chunk from "lodash/chunk"
import { Fragment, useEffect, useState } from "react"
import Flickity from "react-flickity-component"

import { Heading, Icon } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import { Trans } from "@trueskin-web/translations"

import BlogArticleCard from "./blog-article-card"

let flickerRef

const BlogSliderControls = ({ currentSlide, pages }) => (
  <div
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      mt: 7,
    }}
  >
    <div sx={{ minWidth: "120px", textAlign: "right" }}>
      <button
        disabled={currentSlide === 0}
        onClick={() => flickerRef?.previous()}
        sx={{
          m: 0,
          mx: 3,
          p: 0,
          border: 0,
          background: "none transparent",
          fontSize: 2,
          cursor: "pointer",
          "&:hover": {
            color: "primaryLight",
          },
          "&:focus": {
            outline: "none",
          },
          "&:disabled": {
            color: "grey",
            cursor: "default",
            "&:hover": { color: "grey" },
          },
        }}
      >
        <Icon
          icon={arrowRightIcon}
          size="sm"
          sx={{
            position: "relative",
            top: "-1px",
            transform: "rotate(180deg)",
          }}
        />
        <Trans i18nKey="BlogSlider.previous" />
      </button>
    </div>

    {Array(pages)
      .fill(null)
      .map((e, k) => (
        <button
          key={`blog-slider-paginator-${k}`}
          onClick={() => flickerRef?.select(k)}
          title={`Slide ${k + 1}`}
          type="button"
          aria-label={`Slide ${k + 1}`}
          sx={{
            position: "relative",
            size: "8px",
            m: 0,
            mx: 1,
            p: 0,
            border: 0,
            borderRadius: "50%",
            background: currentSlide === k ? "text" : "grey",
            cursor: "pointer",
            "&:hover": {
              background: "primaryLight",
            },
            "&:focus": {
              outline: "none",
            },
          }}
        />
      ))}

    <div sx={{ minWidth: "120px", textAlign: "left" }}>
      <button
        disabled={currentSlide === pages - 1}
        onClick={() => flickerRef?.next()}
        sx={{
          m: 0,
          mx: 3,
          p: 0,
          border: 0,
          background: "none transparent",
          fontSize: 2,
          cursor: "pointer",
          "&:hover": {
            color: "primaryLight",
          },
          "&:focus": {
            outline: "none",
          },
          "&:disabled": {
            color: "grey",
            cursor: "default",
            "&:hover": { color: "grey" },
          },
        }}
      >
        <Trans i18nKey="BlogSlider.next" />
        <Icon
          icon={arrowRightIcon}
          size="sm"
          sx={{ position: "relative", top: "-1px" }}
        />
      </button>
    </div>
  </div>
)

const BlogSlider = ({
  heading,
  headingProps,
  items,
  gridColumns = 4,
  itemsPerPage,
}) => {
  const [currentSlide, setSlide] = useState(0)

  useEffect(() => {
    if (!flickerRef) {
      return
    }

    flickerRef.on("change", (indx) => {
      setSlide(indx)
    })
  }, [])

  if (!Array.isArray(items) || !items.length) {
    return null
  }

  const maxItemsPerPage = itemsPerPage || gridColumns
  const pages = Math.ceil(items.length / maxItemsPerPage)
  const sections = chunk(items, maxItemsPerPage)

  return (
    <Fragment>
      {heading && <Heading {...headingProps}>{heading}</Heading>}
      <div
        sx={{
          display: ["none", "block"],
          width: "100%",
          overflow: "hidden",
          "& *": { outline: "none" },
        }}
      >
        <Flickity
          flickityRef={(c) => (flickerRef = c)}
          options={{
            cellAlign: "left",
            prevNextButtons: false,
            pageDots: false,
            contain: true,
          }}
        >
          {sections.map((section, indx) => (
            <div
              key={`slider-section-${indx}`}
              sx={{
                display: "grid",
                width: "100%",
                flexGrow: 0,
                flexShrink: 0,
                gridTemplateColumns: ["auto", "repeat(4, 1fr)"],
                gap: [6, 5],
                mr: 5,
                mb: 7,
                // scrollSnapAlign: "start",
                // scrollSnapStop: "always",
                scrollBehavior: "smooth",
                "&:last-of-type": {
                  mr: 0,
                },
              }}
            >
              {section.map((item) => (
                <BlogArticleCard
                  key={`${item?.id}${item?.heading?.replace(/\s/gi, "-")}-card`}
                  {...item}
                />
              ))}
            </div>
          ))}
        </Flickity>
      </div>

      <div
        sx={{
          position: "relative",
          display: ["flex", "none"],
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          width: "calc(100% + 24px)",
          mx: -3,
          overflowX: "auto",
          scrollSnapType: "x mandatory",
          webkitOverflowScrolling: "touch",
        }}
      >
        {items.map((item) => (
          <BlogArticleCard
            key={`${item?.id}${item?.heading?.replace(/\s/gi, "-")}-card`}
            {...item}
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              width: "calc(75% - 12px)",
              mx: 3,
              scrollSnapAlign: "start",
              scrollSnapStop: "always",
              scrollBehavior: "smooth",
              scrollMarginLeft: "12px",
            }}
          />
        ))}
      </div>

      {/* Control bar */}
      <BlogSliderControls currentSlide={currentSlide} pages={pages} />
    </Fragment>
  )
}

export default BlogSlider
